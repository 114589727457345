@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  touch-action: none;
  color: rgb(var(--foreground-rgb));
}

.react-flow__attribution {
  display: none;
}

/* Scrollbar Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #525471;
  border-radius: 9999px;
  border: 10px solid #525471;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #525471;
  opacity: 80;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: white;
}

.react-pdf__Page__textContent {
  display: none;
}

.ql-toolbar.ql-snow {
  border: none !important;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  border-radius: 24px;
  background-color: #bbbccc;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-editor {
  margin-top: 20px !important;
  border-radius: 24px;
  padding: 12px 15px;
  background-color: transparent !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25) inset !important;
}

/*Remove Arrows, Spinners*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Hide scrollbar */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.list-custom ol {
  list-style-type: none; /* Removes default numbering */
  counter-reset: item; /* Resets the counter */
  margin: 0;
  padding: 0;
}

.list-custom li {
  display: table;
  counter-increment: item; /* Increments the counter */
  margin-bottom: 0.6em;
}

.list-custom li:before {
  content: counters(item, '.') '. '; /* Adds numbering with dots */
  display: table-cell;
  padding-right: 0.6em;
}

.list-custom li li {
  margin: 0;
}

.list-custom li li:before {
  content: counters(item, '.') ' '; /* Nested numbering */
}

.ql-toolbar.ql-snow {
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.25) !important;
  border-width: 1px !important;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  border-radius: 8px;
  background-color: transparent !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-editor {
  margin-top: 20px !important;
  border-radius: 8px;
  padding: 12px 15px;
  background-color: transparent !important;
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.25) !important;
  border-width: 1px !important;
  box-shadow: none !important;
}
